@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/Archivo/Archivo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/Archivo/Archivo-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/Archivo/Archivo-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/Archivo/Archivo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Archivo';
  src: url('./assets/fonts/Archivo/Archivo-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Palmer';
  src: url('./assets/fonts/PalmerLakePrint/PalmerLakePrint-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
}

#root {
  background: #d2eeff;
}

.dark #root {
  background: #0c111d; /* Dark mode background color */
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.scale-in-left {
  -webkit-animation: scale-in-left 0.5s ease-in-out both;
  animation: scale-in-left 0.5s ease-in-out both;
}

.recharts-surface {
  padding: 3px 0px;
}

.swiper-button-next {
  color: #fff !important;
}

.swiper-button-prev {
  color: #fff !important;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.5 !important;
}
.text-mini {
  font-size: 10px;
}
.text-mini-danger {
  font-size: 11px;
  color: #d92d21;
}
.decline-input {
  padding: 6px;
  border: 1px solid #808ea2;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
  margin-top: 12px;
}

@-webkit-keyframes scale-in-left {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    opacity: 1;
  }
}
@keyframes scale-in-left {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    opacity: 1;
  }
}

.label-visible {
  max-width: 100%;
  opacity: 1;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.pulse {
  animation: pulse 1.5s infinite;
}

@layer components {
  .call-out-txt {
    display: flex;
    padding: 12px 12px 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px 6px 6px 8px;
    background: #f3f7fa;
    border-left-width: 5px;
    border-left-color: #313233;
  }

  .trend-up {
    @apply flex items-center justify-center ml-2 bg-[#E2FAF1] px-2 p-1 rounded-md text-[#428B6E];
  }
  .trend-down {
    @apply flex items-center justify-center ml-2 bg-[#FCDADA80] px-2 p-1 rounded-md text-[#EF4444] dark:text-[red] dark:bg-[#ffffff80];
  }

  .table-render-column {
    @apply text-tremor-content text-sm font-inter font-medium text-gray-700 p-2 dark:text-white capitalize;
  }

  .title-text {
    @apply font-bold text-3xl text-transparent bg-clip-text bg-gradient-to-r from-[#00375F] to-[#005C9F] dark:from-[#ddd] dark:to-[#ffffff];
  }

  .title-text-secondary {
    @apply font-bold text-xl text-transparent bg-clip-text bg-gradient-to-r from-[#00375F] to-[#005C9F] dark:from-[#ddd] dark:to-[#ffffff];
  }

  .card-item {
    @apply p-8;
  }

  .hr-separator {
    @apply w-full h-[1px] bg-separatorBg dark:bg-dark-tremor-background;
  }
}

@media screen and (max-width: 2000px) {
  .trend-title {
    font-size: 0.7vw;
  }
}

@media screen and (max-width: 1700px) {
  .trend-title {
    font-size: 0.6vw;
  }
}

@media screen and (max-width: 1550px) {
  .trend-title {
    font-size: 0.5vw;
  }
}
.table-shadow {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.text-editor {
  width: 100%;
  color: black;
  .ql-editor {
    min-height: 200px;
    @apply dark:text-white;
  }

  .ql-container {
    padding-top: 10px;
  }
  li {
    margin-left: 0;
  }
  .ql-toolbar {
    border: 1px solid #ccc;
  }
}

.myDatePicker {
  width: 100%;
}

@layer components {
  .react-datepicker__month-container {
    @apply bg-[#fff] dark:bg-[#374151] dark:text-white;
  }
}

@layer components {
  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply bg-white dark:bg-[#374151] border border-[#808EA2] dark:border-neutral-700 hover:border-neutral-400 dark:hover:border-neutral-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-neutral-100 dark:bg-neutral-700 border-2 border-neutral-300 dark:border-neutral-600;
  }

  .my-react-select-container .my-react-select__menu-list {
    @apply bg-neutral-100 dark:bg-[#374151];
  }

  .my-react-select-container .my-react-select__option {
    @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-[#374151] dark:hover:bg-neutral-800;
  }
  /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  } */

  .my-react-select-container .my-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply text-neutral-600 dark:text-neutral-200;
  }
}

@media screen and (max-height: 1200px) {
  .tableHeight {
    height: 730px;
  }
}
@media screen and (max-height: 800px) {
  .tableHeight {
    height: 510px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1016px) {
  .css-jc9gdh-control {
    width: calc(100vw - 200px) !important;
    max-width: 800px !important;
  }
}

@media screen and (max-height: 1200px) {
  .tableHeightWHeader {
    height: 668px;
  }
}

@media screen and (max-height: 800px) {
  .tableHeightWHeader {
    height: 455px;
  }
}

.text-editor {
  width: 100%;
}

.text-editor .ql-editor {
  min-height: 200px;
}

.text-editor .ql-container {
  padding-top: 10px;
  border: 1px solid #808ea2 !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.text-editor ul li {
  margin-left: 0;
}

.text-editor .ql-toolbar {
  border: 1px solid #808ea2 !important;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: none !important;
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.65, 0.94)
    both;
  animation: scale-in-center 0.3s cubic-bezier(0.25, 0.46, 0.65, 0.94) both;
}

.removeNotify {
  background: #a7a7a7;
  border-radius: 50%;
  padding: 0px 4px 1px;
  display: inline-block;
  line-height: 14px;
  color: #fff;
  transition: ease all 0.25s;
  border: 0;
}
.removeNotify:hover {
  background: blue;
  cursor: pointer;
}

.blink-1 {
  -webkit-animation: blink-1 3s ease-in-out infinite both;
  animation: blink-1 3s ease-in-out infinite both;
}
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.change-request-insights .react-datepicker-wrapper {
  vertical-align: middle;
  margin: 0 12px 0 6px;
}

.fill-\[\#FFC265\] {
  fill: #ffc265;
}
.fill-\[\#7DCEFF\] {
  fill: #7dceff;
}
.fill-\[\#019AA3\] {
  fill: #019aa3;
}
.fill-\[\#00199D\] {
  fill: #00199d;
}
.fill-\[\#002943\] {
  fill: #002943;
}

.text-\[\#FFC265\] {
  --tw-text-opacity: 1;
  color: #ffc265;
}
.text-\[\#7DCEFF\] {
  --tw-text-opacity: 1;
  color: #7dceff;
}
.text-\[\#019AA3\] {
  --tw-text-opacity: 1;
  color: #019aa3;
}
.text-\[\#00199D\] {
  --tw-text-opacity: 1;
  color: #00199d;
}
.text-\[\#002943\] {
  --tw-text-opacity: 1;
  color: #002943;
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1/4);
    transform: scale(1/4);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1/4);
    transform: scale(1/4);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.change-search input {
  min-width: 400px;
}
.change-search div div:nth-of-type(2) {
  display: none;
}
.react-datepicker-wrapper
  .react-datepicker__input-container
  .dark
  .react-datepicker__month-container {
  background-color: #161b26;
  color: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.no-error,
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon-container {
  margin-bottom: 8px;
}

.dark .my-react-select.css-obqdaj-control {
  background: #374151;
  color: #fff;
  border: #374151;
}

.tooltip-container {
  width: 100%;
}

.tooltip-text {
  text-overflow: ellipsis;
  white-space: wrap;
}

.text-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rounded-bg {
  background: url('./assets/images/bgrounded.png');
  background-position: center;
  background-size: cover;
}

.tools-page .tabs {
  margin-bottom: 20px;
}

.tools-page .tabs button {
  color: #0056b3;
  border: none;
  cursor: pointer;
}

.tools-page .tabs button:hover {
  color: #0056b3;
  border-bottom: 1px solid;
}

.tools-page .tabs button.active {
  color: blue;
  border-bottom: 1px solid;
}

.tools-page .tab-content {
  padding: 20px;
  border: 1px solid #ddd;
}

.tools-submission-warning{
  background: #fff2da;
  padding: 12px;
  color: rgb(243 99 0);
  border-radius: 6px;
}

.tools-submission-danger{
  background: #fff2da;
  padding: 12px;
  color: rgb(243 99 0);
  border-radius: 6px;
}

.tools-submission-success {
  background: #d6ffdd;
  padding: 12px;
  color: rgb(0 102 37);
  border-radius: 6px;
}

/* KPI Filters */
.kpis {
  border: 13px solid #d2eeff;
  background: white;
}
.kpi-filters {
  background: #fff;
  margin-bottom: 4px;
  padding: 8px 12px;
}
.kpi-filters label {
  display: inline-block;
}
.kpi-filters.checks label {
  display: -webkit-inline-box;
  margin-right: 16px;
}
.kpi-filters label span {
  display: block;
  font-size: 12px;
  padding: 0 0.5rem;
}
.kpi-filters h6 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 4px;
}
.kpis table {
  font-size: 14px;
}
.kpis table thead {
  text-align: left;
  cursor:pointer;
}
.kpi-table svg {
  float:right
}
.kpis table td,
.kpis table th {
  min-width: 100px;
}
.kpi-suggestions {
  z-index: 99;
  padding: 2px 12px;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
}
.kpi-tags {
  font-size: 14px;
  background: #0029ff;
  color: #fff;
}
.styled-checkbox {
  width: 16px;
  height: 16px;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.styled-checkbox:checked {
  background-color: currentColor;
  border: 1px solid transparent;
}

.styled-checkbox:checked::after {
  content: '✔';
  color: white;
  font-size: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-section {
  scroll-margin-top: 60px; /* Adjust the margin based on the height of your sticky nav */
}
.kpi-modal-overlay {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
}

.kpi-modal-overlay.open {
  right: 0;
}

.kpi-modal-content {
  background-color: #fff;
  height: 100%;
  padding: 20px;
  width: 100%;
  max-width: 40%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
}

.kpi-close-modal-button {
  background: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 30px;
  background: #e0e0e0;
  padding: 2px 14px 4px;
  line-height: 30px;
  border-radius: 6px;
}
.kpi-filters svg {
  float: right;
}

.kpis .modal-body .multi-select {
  margin-bottom: 1.5rem;
}

.kpis .modal-body .select-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
  min-height: 40px;
}

.kpis .modal-body .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  flex-grow: 1;
  padding-right: 20px; /* Space for arrow */
}

.kpis .modal-body .tag {
  background-color: #f7f8f9;
  color: #405572;
  padding: 0.3rem 0.7rem;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.kpis .modal-body .tag button {
  background: none;
  border: none;
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  color: #405572;
}

.kpis .modal-body .tag button:hover {
  color: #ff4040;
}

.kpis .modal-body .placeholder {
  color: #aaa;
  font-size: 14px;
}

.kpis .modal-body .arrow {
  position: absolute;
  right: 10px;
  color: #999;
  font-size: 12px;
}

.kpis .modal-body .dropdown {
  display: block;
  position: relative;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  z-index: 10;
}

.kpis .modal-body .dropdown-item {
  padding: 0.5rem;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.kpis .modal-body .dropdown-item:hover {
  background-color: #f0f0f0;
}

@keyframes loading-bar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.animate-loading-bar {
  animation: loading-bar 1.5s ease-in-out infinite;
}

/* React grid layout table max height */
.react-grid-item.table .analytics-table {
  max-height: 250px !important;
}
.react-grid-item.value,
.react-grid-item.value .tremor-Card-root {
  min-height: 90px;
  height: 90px !important;
}
.react-grid-layout.layout{
  overflow: auto;
  width: 100%;
}

.grid-item-container.googleAds.campaignDevices{
  padding: 0px !important;
  border-width: 0px !important;
  box-shadow: none;
}